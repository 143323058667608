import $ from 'jquery'

$('.menubtn').click(function (event) {
	$('.header-menu2').slideToggle(1000);
});
var headertop = $('.header-fix1').height();
var h2 = $('.header-menu2').height();
$(window).scroll(function () {
	if ($(window).scrollTop() > headertop) {
		$('.header-menu1').hide();
	}
	else {
		$('.header-menu1').show();
	}
	
});