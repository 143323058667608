import $ from 'jquery';
import Inputmask from 'inputmask';

function verifyForm(Form) {
	let phone = $(Form).find('[name="phone"]');
	var email = $(Form).find('[name="email"]');
	var name = $(Form).find('[name="name"]');
	let errors = false;
	
	for (let i = 0; i < $(Form).find('[required]').length; i++) {
		let element = $(Form).find('[required]').eq(i);
		element.next().removeClass('show');
		if (element.val() == '') {
			element.addClass('error')
				.next().addClass('show')
				.text('Поле обязательно для заполнения');
			errors = true;
		}
	}
	
	if (phone.length) {
		let value = phone.val();
		let phone_pattern = /7\d{10}/;
		if (value == '') {
		} else if (/_/gi.test(value)) {
			alert('Введите корректный номер телефона');
			/*phone.addClass('error')
				.next().addClass('show')
				.text('Введите корректный номер телефона');*/
			
			errors = true;
		} else if (!phone_pattern.test(value.replace(/\D/gi, ''))) {
			/*phone.addClass('error')
				.next().addClass('show')
				.text('Введите корректный номер телефона');*/
			alert('Введите корректный номер телефона');
			console.log(value.replace(/\D/gi, ''));
			errors = true;
		}
	}
	if (name.length) {
		let value = name.val();
		if (/[a-zA-Z\d]/gi.test(value)) {
			alert('Цифры и латинские буквы в имени недопустимы!');
			/*phone.addClass('error')
				.next().addClass('show')
				.text('Введите корректный номер телефона');*/
			
			errors = true;
		}
	}
	if (email.length) {
		value = email.val();
		var regexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
		
		if (value != '' && !regexp.test(value)) {
			email.addClass('msg-error')
				.next().addClass('show')
				.text('Введите корректный адрес электронной почты');
			
			errors = true;
		}
	}
	
	return errors;
}

function parseValues(values) {
	let result = {};
	
	for (let i = 0; i < values.length; i++)
		result[values[i].name] = values[i].value;
	
	return result;
}

function showSuccessMsg() {
	$('.form-body').hide();
	$('.form-msg').show();
	const modal_callback = $('#modal-callback');
	if (! modal_callback.hasClass('is-visible')) {
		modal_callback.addClass('is-visible')
	}
}

function comagic(phone) {
	if (window.Comagic) {
		const siteId = '';
		var time = +new Date() + 10000;
		Comagic.sitePhoneCall({
			captcha_key: '',
			captcha_val: '',
			phone: phone,
			delayed_call_time: time.toString(),
			/*group_id: siteId,*/
		}, function (resp) {
		
		});
	}
}

function close_modal() {
	const modal_callback = $('#modal-callback');
	$('.form-body').show();
	$('.form-msg').hide();
	modal_callback.removeClass('is-visible');
}

function sendForm(formData, Form) {
	$.ajax({
		type: 'POST',
		url: Form.action,
		data: formData,
		dataType: 'json',
		success: function (data) {
			if (data.status) {
				showSuccessMsg();
			}
			else {
				alert("Возникли ошибки:\n - " + data.errors.join("\n - "));
			}
		},
		error: function (e) {
			console.log(e.responseJSON);
			alert(e.responseJSON.detail);
		}
	});
}

/*Маска ввода телефона*/
const im = new Inputmask("+7(999)999-99-99");
const phoneFields = document.querySelectorAll(".phone");
im.mask(phoneFields);

$('.callback-form').submit(function (e) {
	e.preventDefault();
	var Form = this;
	if (verifyForm(Form)) {
		return;
	}
	let phone = $(Form).find('[name="phone"]').val();
	comagic(phone);
	let formData = parseValues($(Form).serializeArray());
	sendForm(formData, Form);
});


/*Открытие/закрытие модального окна*/
$(document).ready(function () {
	const modal_callback = $('#modal-callback');
	const modal_close = modal_callback.find('.modal_close');
	const modal_overlay = modal_callback.find('.overlay');
	const modal_title = modal_callback.find('.modal-title');
	$('.open_modal').on('click', function (event) {
		event.preventDefault();
		let title = $(this).data('title');
		if (!title) {
			title = 'Заказать звонок';
		}
		modal_title.html(title + ':');
		modal_callback.addClass('is-visible');
	});
	modal_close.on('click', close_modal);
	modal_overlay.on('click', close_modal);
});