import $ from 'jquery'

function quest_timer() {
    quest_timer_count -= 1;
    $('#quest_timer').html(quest_timer_count);
    if(quest_timer_count <= 0){
        $('#quest_timer').html('VS');
    }else{
        setTimeout(quest_timer, 3000);
    }
}

let quest_step = 0;
let quest_timer_count = 30;

$(document).ready(function () {
	$('.send_form_quest').on('click', function (event) { 

		if(quest_step === 0){
            if(!$('#select_1').is(":checked") && !$('#select_2').is(":checked")){
                alert('Выберите вариант!');
                return false;
            }
            $('#callback_popup_quest_title').html('Пройдите опрос из 9 вопросов');
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Как часто обращаетесь в автосервис?</h5><div class="form-group"><input type="checkbox" id="select_1"><label for="select_1">1 раз в сезон</label></div><div class="form-group"><input type="checkbox" id="select_2"><label for="select_2">2 раза в сезон</label></div><div class="form-group"><input type="checkbox" id="select_3"><label for="select_3">1 раз в 6 месяцев</label></div><div class="form-group"><input type="checkbox" id="select_4"><label for="select_4">2 раза в 9 месяцев</label></div><div class="form-group"><input type="checkbox" id="select_5"><label for="select_5">Не обращаюсь, машина новая</label></div>');
            quest_step += 1;
        }else if(quest_step === 1){
            if(!$('#select_1').is(":checked") && !$('#select_2').is(":checked") && !$('#select_3').is(":checked") && !$('#select_4').is(":checked") && !$('#select_5').is(":checked")){
                alert('Выберите вариант!');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Какую акцию выбрали бы при обращении в автосервис?</h5><div class="form-group"><input type="checkbox" id="select_1"><label for="select_1">Диагностика в подарок</label></div><div class="form-group"><input type="checkbox" id="select_2"><label for="select_2">Восстановление акпп / двигателя</label></div><div class="form-group"><input type="checkbox" id="select_3"><label for="select_3">Проверка сход-развала</label></div><div class="form-group"><input type="checkbox" id="select_4"><label for="select_4">Эвакуация автомобиля</label></div><div class="form-group"><input type="checkbox" id="select_5"><label for="select_5">Не знаю</label></div>');
            quest_step += 1;
        }else if(quest_step === 2){
            if(!$('#select_1').is(":checked") && !$('#select_2').is(":checked") && !$('#select_3').is(":checked") && !$('#select_4').is(":checked") && !$('#select_5').is(":checked")){
                alert('Выберите вариант!');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Кто больше всего создает на дороге опасность?</h5><div class="form-group"><textarea id="textarea_quest" style="resize: none;width: 100%;height: 150px;" placeholder="Напишите на Вашем личном примере. Например водители дорогих иномарок, таксисты или газелисты."></textarea></div>');
            quest_step += 1;
        }else if(quest_step === 3){
            if($('#textarea_quest').val().length <= 1){
                alert('Напишите про свой опыт');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Какая цена за парковку Вас устраивает?</h5><div class="form-group"><textarea id="textarea_quest" style="resize: none;width: 100%;height: 150px;" placeholder="Напишите, какая цена Вас устраивает за парковку в центре города. Например 40 рублей за 1 час."></textarea></div>');
            quest_step += 1;
        }else if(quest_step === 4){
            if($('#textarea_quest').val().length <= 1){
                alert('Напишите про свой опыт');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Сколько в вашей семье автомобилей?</h5><div class="form-group"><input type="checkbox" id="select_1"><label for="select_1">1 автомобиль</label></div><div class="form-group"><input type="checkbox" id="select_2"><label for="select_2">2 автомобиля</label></div><div class="form-group"><input type="checkbox" id="select_3"><label for="select_3">3 автомобиля</label></div><div class="form-group"><input type="checkbox" id="select_4"><label for="select_4">Более 3-х автомобилей</label></div><div class="form-group"><input type="checkbox" id="select_5"><label for="select_5">Не знаю</label></div>');
            quest_step += 1;
        }else if(quest_step === 5){
            if(!$('#select_1').is(":checked") && !$('#select_2').is(":checked") && !$('#select_3').is(":checked") && !$('#select_4').is(":checked") && !$('#select_5').is(":checked")){
                alert('Выберите вариант!');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Во сколько лет Вы купили свой первый автомобиль?</h5><div class="form-group"><input type="checkbox" id="select_1"><label for="select_1">До 18 лет</label></div><div class="form-group"><input type="checkbox" id="select_2"><label for="select_2">До 20 лет</label></div><div class="form-group"><input type="checkbox" id="select_3"><label for="select_3">До 30 лет</label></div><div class="form-group"><input type="checkbox" id="select_4"><label for="select_4">До 40 лет</label></div><div class="form-group"><input type="checkbox" id="select_5"><label for="select_5">Не знаю (нет машины)</label></div>');
            quest_step += 1;
        }else if(quest_step === 6){
            if(!$('#select_1').is(":checked") && !$('#select_2').is(":checked") && !$('#select_3').is(":checked") && !$('#select_4').is(":checked") && !$('#select_5').is(":checked")){
                alert('Выберите вариант!');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Сколько Вы тратите на дорогу на работу?</h5><div class="form-group"><textarea id="textarea_quest" style="resize: none;width: 100%;height: 150px;" placeholder="Напишите, больше 1 часа по городу, или работаю не далеко, занимает около 20-25 минут."></textarea></div>');
            quest_step += 1;
        }else if(quest_step === 7){
            if($('#textarea_quest').val().length <= 1){
                alert('Напишите про свой опыт');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Почему Вам надоели пробки, сколько тратите на дорогу?</h5><div class="form-group"><textarea id="textarea_quest" style="resize: none;width: 100%;height: 150px;" placeholder="Напишите, на сколько вы устали от пробок, сколько тратите в день от дома до работы и обратно."></textarea></div>');
            quest_step += 1;
        }else if(quest_step === 8){
            if($('#textarea_quest').val().length <= 1){
                alert('Напишите про свой опыт');
                return false;
            }
            $('#body_quest').html('<h5 style="margin-bottom: 30px;text-align: center;">Спасибо, за пройденный опрос</h5><div class="form-group"><h4 id="quest_timer" style="text-align: center;color: #b51726;font-size: 70px;margin: 40px 0 40px 0;">30</h4></div>');
            quest_step += 1;
            setTimeout(quest_timer, 3000);
        }else{
            $('#callback_popup_quest').modal('hide');
            $('#quest_timer').html('<h5 style="margin-bottom: 30px;text-align: center;">Спасибо, за пройденный опрос</h5>');
            quest_step = 0;
        }

	});
});