
import '../scss/app.scss';
import './blocks/header'
import './blocks/callback-popup'
import './blocks/quest'
import './blocks/services_menu'
import './blocks/special_offers'
import './blocks/read_more'
import './blocks/price-list'
import './blocks/map'
import './blocks/mango'
import './global/swiper_sliders'
import './global/fancybox_init'
import './libs/lazy_youtube'
import 'bootstrap'

import LazyLoad from './libs/lazyload.min'
new LazyLoad({
	elements_selector: ".lazy"
});

