import $ from 'jquery'

let hidden_service = $('.services_menu__item_hidden');
let show_all_services = $('.show-all-services');
show_all_services.on('click',function() {
	if (! hidden_service.is(":visible")) {
		hidden_service.slideDown(500);
		show_all_services.html('Скрыть');
	}else{
		hidden_service.hide(500);
		show_all_services.html('Показать все услуги');
	}
});