import $ from 'jquery';

//Переменная для включения/отключения индикатора загрузки
const spinner = $('.ymap-container').children('.loader');
//Переменная для определения была ли хоть раз загружена Яндекс.Карта (чтобы избежать повторной загрузки при наведении)
let check_if_load = false;

//Функция создания карты сайта и затем вставки ее в блок с идентификатором "map-yandex"
function init() {
	const myMapTemp = new ymaps.Map("map-yandex", {
		center: [55.785793, 37.545907], // координаты центра на карте
		zoom: 10, // коэффициент приближения карты
		controls: ['zoomControl', 'fullscreenControl'] // выбираем только те функции, которые необходимы при использовании
	});
	const myPlacemark1 = new ymaps.Placemark([55.892168, 37.524121], {
		balloonContent: '<strong>Улица Лобненская, дом 17</strong><br><br><center class="popup-call text-block__reviews-link">+7 (499) 495-41-52</center><br>',
	}, {
		iconLayout: 'default#image',
		iconImageHref: '/img/map/map-baloon.png'
	});
	myMapTemp.geoObjects.add(myPlacemark1); // помещаем флажок на карту

	const myPlacemark2 = new ymaps.Placemark([55.655727, 37.553046], {
		balloonContent: '<strong> Научный проезд, дом 14А, строение 9 (м. Калужская)</strong><br><br><center class="popup-call text-block__reviews-link">+7 (495) 374-78-83</center><br>',
	}, {
		iconLayout: 'default#image',
		iconImageHref: '/img/map/map-baloon.png'
	});
	myMapTemp.geoObjects.add(myPlacemark2); // помещаем флажок на карту
	
	// Получаем первый экземпляр коллекции слоев, потом первый слой коллекции
	const layer = myMapTemp.layers.get(0).get(0);
	
	// Решение по callback-у для определния полной загрузки карты
	waitForTilesLoad(layer).then(function () {
		// Скрываем индикатор загрузки после полной загрузки карты
		spinner.removeClass('is-active');
	});
}

// Функция для определения полной загрузки карты (на самом деле проверяется загрузка тайлов)
function waitForTilesLoad(layer) {
	return new ymaps.vow.Promise(function (resolve, reject) {
		let tc = getTileContainer(layer), readyAll = true;
		tc.tiles.each(function (tile, number) {
			if (!tile.isReady()) {
				readyAll = false;
			}
		});
		if (readyAll) {
			resolve();
		} else {
			tc.events.once("ready", function () {
				resolve();
			});
		}
	});
}

function getTileContainer(layer) {
	for (const k in layer) {
		if (layer.hasOwnProperty(k)) {
			if (
				layer[k] instanceof ymaps.layer.tileContainer.CanvasContainer
				|| layer[k] instanceof ymaps.layer.tileContainer.DomContainer
			) {
				return layer[k];
			}
		}
	}
	return null;
}

// Функция загрузки API Яндекс.Карт по требованию (в нашем случае при наведении)
function loadScript(url, callback) {
	const script = document.createElement("script");
	
	if (script.readyState) {  // IE
		script.onreadystatechange = function () {
			if (script.readyState == "loaded" ||
				script.readyState == "complete") {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {  // Другие браузеры
		script.onload = function () {
			callback();
		};
	}
	
	script.src = url;
	document.getElementsByTagName("head")[0].appendChild(script);
}

// Основная функция, которая проверяет когда мы навели на блок с классом "ymap-container"
function ymap(){
	$('.ymap-container').mouseenter(function () {
			if (!check_if_load) { // проверяем первый ли раз загружается Яндекс.Карта, если да, то загружаем
				
				// Чтобы не было повторной загрузки карты, мы изменяем значение переменной
				check_if_load = true;
				
				// Показываем индикатор загрузки до тех пор, пока карта не загрузится
				spinner.addClass('is-active');
				
				// Загружаем API Яндекс.Карт
				loadScript("https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;loadByRequire=1", function () {
					// Как только API Яндекс.Карт загрузились, сразу формируем карту и помещаем в блок с идентификатором "map-yandex"
					ymaps.load(init);
				});
			}
		}
	);
}

$(function () {
	
	//Запускаем основную функцию
	ymap();
	
});